<template>
    <div>
        <v-toolbar flat>
            <v-toolbar-title>{{ $t('title.sync_module') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field v-model="params.search"
                          append-icon="mdi-magnify"
                          :label="$t('input.search')"
                          single-line
                          hide-details
                          clearable
                          @input="fetchListSyncModule(params)"
                          class="mr-5" />
            <v-btn :loading="loading" :disabled="loading" class="ml-5" color="primary" @click="_refreshAllSyncData()">
                {{ $t('button.sync_all') }}
            </v-btn>
        </v-toolbar>
    </div>
</template>

<script>
    import { mapActions } from "vuex";

    export default {
        props: ["darkmode", "status", "params", "dataDelete", "userPermission"],
        data: () => ({
            getLoadingDetail:false,
            detailData:[],
            detailDataCount:[],
            dialog: false,
            loading: false,
        }),
        methods: {
            _refreshAllSyncData(){
                this.loadRefreshAllSyncData();
                // this.dialog = true;
            },
            async loadRefreshAllSyncData(){
                this.loading = true;
                await this.$request
                    .post({
                    url: "sync-module/refreshAllSyncData",
                    data: {},
                    })
                    .then((res) => {
                    if (res.data) {
                        // console.log(res.data);
                        this.loading = false;
                        window.location.reload();
                    }else{
                        
                        this.getLoadingDetail = false;
                    }
                    });
                },
            ...mapActions("$_syncModule", ["fetchPopUp", "fetchListSyncModule", "deleteModule"]),
        },
    };
</script>

<style lang="scss" scoped>
</style>
